import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import ReactGA from 'react-ga4'
import { getGoogleAnalyticsTag } from './env'

/**
 * Redact names when Google Analytics collects paths, such that
 * `/admin/users/view/admin` becomes `/admin/users/view/(name)`.
 *
 * Note that this currently assumes any 4th-level URL is a detail view.
 */
function anonymizePath(path?: string) {
  const splitPath = path?.split?.('/') ?? []
  if (splitPath.length < 4) {
    return path
  } else {
    return [...splitPath.slice(0, 4), '(name)'].join('/')
  }
}

export function setupGoogleAnalytics() {
  const gaTag = getGoogleAnalyticsTag()
  if (gaTag) {
    ReactGA.initialize(gaTag)
  }
}

/**
 * Watches for path changes and sends URLs visited to Google Analytics
 */
export const GoogleAnalyticsPageTracker: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation()
  const path = anonymizePath(location.pathname)

  useEffect(() => ReactGA.send({ type: 'pageview', page: path }), [path])

  return <>{children}</>
}
