import React from 'react'

import {
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
  Palette as PaletteIcon
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'

import { usePTTheme } from 'lib/providers/PTThemeProvider'
import { colorSchemes, getContrastText } from 'external/src/theme'

const ColorSelect = ({ value, onChange, darkMode, label }) => {
  const currentScheme = darkMode ? colorSchemes[value].dark : colorSchemes[value].light
  const currentColor = (currentScheme.primary as { main: string })?.main ?? currentScheme.primary

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      px={2}
      my={2}>
      <Typography>{label}</Typography>
      <FormControl color="secondary">
        <InputLabel color="secondary">{label}</InputLabel>
        <Select
          id={label.replace(/ /g, '-')}
          value={value}
          label={label}
          color="primary"
          SelectDisplayProps={{ color: 'secondary' }}
          onChange={onChange}
          renderValue={(value) => (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box
                sx={{
                  width: '16px',
                  height: '16px',
                  marginRight: '16px',
                  borderRadius: '50%',
                  backgroundColor: currentColor
                }}
              />
              {colorSchemes[value].displayName}
            </Box>
          )}
          sx={{
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
              width: '22ch'
            }
          }}>
          {Object.entries(colorSchemes).map(([name, colorScheme]) => {
            const scheme = darkMode ? colorScheme.dark : colorScheme.light
            const color = (scheme.primary as { main: string })?.main ?? scheme.primary
            const contrastThreshold = darkMode ? 3.5 : 2.5

            return (
              <MenuItem
                key={name}
                value={name}
                sx={{
                  gridColumnStart: 0,
                  gridColumnEnd: 16
                }}>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      backgroundColor: color,
                      color: getContrastText(color, contrastThreshold)
                    }}>
                    <PaletteIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>{colorScheme.displayName}</ListItemText>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

const Appearance = () => {
  const {
    getDarkMode,
    setDarkMode,
    getPrimaryColor,
    setPrimaryColor,
    getAccentColor,
    setAccentColor
  } = usePTTheme()
  const darkMode = getDarkMode()

  const primaryColor = getPrimaryColor()
  const accentColor = getAccentColor()

  const onPrimaryColorChange = (event) => setPrimaryColor(event.target.value)
  const onAccentColorChange = (event) => setAccentColor(event.target.value)

  const onClickThemeDark = () => setDarkMode(true)
  const onClickThemeLight = () => setDarkMode(false)

  return (
    <>
      <Typography gutterBottom>Appearance settings are saved locally using cookies.</Typography>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        px={2}
        my={2}>
        <Typography>Website theme mode</Typography>
        <ToggleButtonGroup
          value={darkMode}
          exclusive
          aria-label="set dark mode"
          color="secondary"
          size="small">
          <ToggleButton
            value={false}
            color="secondary"
            aria-label="light mode"
            onClick={onClickThemeLight}>
            <LightModeIcon sx={{ width: '18px', height: '18px', mr: 1 }} />
            Light
          </ToggleButton>
          <ToggleButton value={true} aria-label="dark mode" onClick={onClickThemeDark}>
            <DarkModeIcon sx={{ width: '18px', height: '18px', mr: 1 }} />
            Dark
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <ColorSelect
        value={primaryColor}
        onChange={onPrimaryColorChange}
        darkMode={darkMode}
        label="Primary Color"
      />
      <ColorSelect
        value={accentColor}
        onChange={onAccentColorChange}
        darkMode={darkMode}
        label="Accent Color"
      />
    </>
  )
}

export default Appearance
