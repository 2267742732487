/**
 * Here is the current list of environment variables:
 *
 *   NODE_ENV: 'production' | 'development' | 'test'
 *
 *   REACT_APP_SSO_ENABLED: boolean (default false)
 *   REACT_APP_ENABLE_INGRESS_MANAGEMENT: boolean (default true)
 *
 *   REACT_APP_API_ROOT: string (url)
 *
 *   REACT_APP_GOOGLE_ANALYTICS_TAG: string
 *   REACT_APP_SENTRY_DSN: string
 *
 *   REACT_APP_FRONTEND_VERSION: string
 *   REACT_APP_FRONTEND_GIT_COMMIT: string
 *   REACT_APP_BACKEND_VERSION: string
 *   REACT_APP_BACKEND_GIT_COMMIT: string
 */

/**
 * Pulls an environment variable in.
 *
 * In order, uses:
 * - The injected `window.environment` in `public/index.html` at runtime.
 * - A `.env` file when the project was built.
 * - Exported variables from the shell or Dockerfile when the project was built.
 *
 * Note that custom environment variables need to be prefixed with `REACT_APP_`
 * to be made available inside the application, with a few predefined exceptions.
 *
 * See https://create-react-app.dev/docs/adding-custom-environment-variables/
 * for more details.
 */
export function getEnvironmentVariable(name: string): string | undefined {
  return window.environment?.[name] ?? process.env?.[name]
}

export function isTestEnvironment() {
  return getEnvironmentVariable('NODE_ENV') === 'test'
}

export function isProductionEnvironment() {
  return getEnvironmentVariable('NODE_ENV') === 'production'
}

export function isSsoEnabled() {
  return getEnvironmentVariable('REACT_APP_SSO_ENABLED') === 'true'
}

export function isIngressManagementEnabled() {
  return getEnvironmentVariable('REACT_APP_ENABLE_INGRESS_MANAGEMENT') !== 'false'
}

/**
 * Returns a graphql endpoint suitable for both local development and production.
 *
 * If the site is being served from localhost, will point to localhost:1200.
 *
 * Otherwise, will point to the endpoint set in the environment or, as a backup,
 * the current domain plus '/api'
 *
 * A typical environment for frontend development is a .env.local in the `plotter/`
 * folder with:
 *
 *    REACT_APP_API_ROOT = 'https://beta.frontend.predictivegrid.dev/api'
 *
 * or, for more granular control:
 *
 *    REACT_APP_API_ROOT_HTTP = 'https://beta.frontend.predictivegrid.dev/api'
 *    REACT_APP_API_ROOT_WS = 'wss://beta.frontend.predictivegrid.dev/api/graphql/subscription'
 */
export function getGraphqlEndpointApiRoot() {
  const environmentApiRootHttp = getEnvironmentVariable('REACT_APP_API_ROOT_HTTP')
  const environmentApiRootWs = getEnvironmentVariable('REACT_APP_API_ROOT_WS')

  if (environmentApiRootHttp && environmentApiRootWs) {
    return {
      http: environmentApiRootHttp,
      ws: environmentApiRootWs
    }
  } else {
    const environmentApiRoot = getEnvironmentVariable('REACT_APP_API_ROOT')
    const apiRootHttp =
      environmentApiRoot ?? `${window.location.protocol}//${window.location.host}/api`
    const apiRootWs = `${apiRootHttp.replace(/^http/, 'ws')}/graphql/subscription`

    return {
      http: apiRootHttp,
      ws: apiRootWs
    }
  }
}

/**
 * Analytics tag for Google Analytics.
 *
 * Not setting this environment variable disables Google Analytics.
 */
export function getGoogleAnalyticsTag() {
  return getEnvironmentVariable('REACT_APP_GOOGLE_ANALYTICS_TAG')
}

/**
 * ID for Sentry reporting.
 *
 * Not setting this environment variable disables Sentry reporting.
 */
export function getSentryDsn() {
  return getEnvironmentVariable('REACT_APP_SENTRY_DSN')
}

/**
 * Version of the project. Displayed in settings under "about". Populated from
 * package.json at build time.
 */
export function getFrontendVersion() {
  return getEnvironmentVariable('REACT_APP_FRONTEND_VERSION')
}

/**
 * Build ID of the project. Displayed in settings under "about". Populated from
 * git at build time.
 */
export function getFrontendGitCommit() {
  return getEnvironmentVariable('REACT_APP_FRONTEND_GIT_COMMIT')
}

/**
 * Version of the backend. Displayed in settings under "about". Populated from
 * smartgridstore at build time.
 */
export function getBackendVersion() {
  return getEnvironmentVariable('REACT_APP_BACKEND_VERSION')
}

/**
 * Build ID of the backend. Displayed in settings under "about". Populated from
 * git in smartgridstore at build time.
 */
export function getBackendGitCommit() {
  return getEnvironmentVariable('REACT_APP_BACKEND_GIT_COMMIT')
}
