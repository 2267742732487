export default {
  SHARED: {
    COMPONENTS: {
      LICENSES: {
        INTRO_LINE: 'This software is made possible thanks to:',
        FULL_DISCLAIMER_LINK:
          'A full list of software that may be used in this program with their corresponding licenses ' +
          'is available <1>here</1>.',
        LICENSE: 'Released under the {{license}} license.',
        AUTHOR_WITH_LINK: 'By <1>{{vendorName}}</1>. ',
        AUTHOR_WITHOUT_LINK: 'By {{vendorName}}. '
      }
    },
    VALIDATION: {
      EMAIL: {
        REQUIRED: 'Email is required',
        INVALID: 'Please enter a valid email',
        NOT_UNIQUE: 'A user with that email already exists'
      },
      GROUP: {
        REQUIRED: 'Group name is required',
        INVALID: 'Group name can only contain characters A-Z, a-z, and 0-9'
      },
      INGRESS: {
        REQUIRED: 'Ingress name is required',
        INVALID: 'Ingress names may only contain the characters a-z, A-Z, 0-9 and _',
        NOT_UNIQUE: 'A ingress with that name already exists',
        CLASS_REQUIRED: 'Ingress class is required',
        PREFIX_REQUIRED: 'Collection prefix is required',
        STREAMS_REQUIRED: 'At least one stream is required',
        FREQUENCY_REQUIRED: 'Streams require at least 1Hz of generated data',
        TARGET_REQUIRED: 'Target is required',
        ID_REQUIRED: 'ID is required',
        QUERY_REQUIRED: 'Query is required',
        TIMESTAMP_INVALID: 'Timestamp rounding, if provided, must be a positive integer'
      },
      PASSWORD: {
        REQUIRED: 'Password is required',
        INVALID_LENGTH: 'Password must be at least 12 characters',
        INVALID_REQUIRED_CHARS:
          'Password must contain a lowercase letter, an uppercase letter, and a number',
        INVALID_CHARS:
          'Password may only contain characters a-z, A-Z, 0-9, and the symbols !@#$%^&*()'
      },
      USER: {
        REQUIRED: 'Username is required',
        INVALID: 'Username may only contain the characters a-z, 0-9, and _',
        NOT_UNIQUE: 'A user with that name already exists'
      },
      VERIFY_PASSWORD: {
        REQUIRED: 'Verify password is required',
        PASSWORDS_DONT_MATCH: 'Passwords do not match'
      }
    },
    ERROR: {
      TOKEN_EXPIRED: 'Your session has expired. Please log in again to continue.',
      JSON_WEB_TOKEN: 'Your session token is invalid. Please log in again to continue.',
      INVALID_CREDENTIALS: 'The username or password provided was incorrect. Please try again.',
      MISSING_ADMIN_CAPABILITY: 'You need administrator privileges to access this page.',
      JWT_EXPIRED: 'Your session has expired. Please log in again to continue.',
      REQUIRES_BASIC_AUTH_HEADER: 'You need to log in to access this page.'
    }
  }
} as const
