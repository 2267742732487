import React, { useContext, useEffect, useMemo, useState } from 'react'
import { createTheme, ThemeProvider, Theme } from '@mui/material'

import { retrieveCookie, storeCookie } from 'lib/cookies'
import { generateTheme } from 'external/src/theme'

export type ThemeColorValue = string | [light: string, dark: string]
export type ThemeColor = [string, ThemeColorValue]

interface PTThemeProviderApi {
  getDarkMode: () => boolean
  setDarkMode: (boolean) => void
  getPrimaryColor: () => string
  setPrimaryColor: (name: string) => void
  getAccentColor: () => string
  setAccentColor: (name: string) => void
}

const PTThemeContext = React.createContext<PTThemeProviderApi>({
  getDarkMode: () => false,
  setDarkMode: (_) => null,
  getPrimaryColor: () => undefined,
  setPrimaryColor: (name) => undefined,
  getAccentColor: () => undefined,
  setAccentColor: (name) => undefined
})

declare global {
  interface Window {
    theme: Theme
  }
}

interface PTThemeProviderProps {
  children?: React.ReactNode
}
const PTThemeProvider: React.FC<PTThemeProviderProps> = ({ children }) => {
  const [darkMode, setDarkMode] = useState(retrieveCookie('dark_mode') === 'true')

  const [primaryColor, setPrimaryColor] = useState(
    retrieveCookie('color_primary_name') ?? 'PingThingsBlue'
  )
  const [accentColor, setAccentColor] = useState(
    retrieveCookie('color_accent_name') ?? 'PingThingsOrange'
  )

  const getDarkMode = () => darkMode
  const getPrimaryColor = () => primaryColor
  const getAccentColor = () => accentColor

  useEffect(() => {
    storeCookie('dark_mode', darkMode)
  }, [darkMode])
  useEffect(() => {
    storeCookie('color_primary_name', primaryColor)
  }, [primaryColor])
  useEffect(() => {
    storeCookie('color_accent_name', accentColor)
  }, [accentColor])

  const themeProps = useMemo(() => {
    try {
      return generateTheme(primaryColor, accentColor, darkMode)
    } catch {
      setPrimaryColor('PingThingsBlue')
      setAccentColor('PingThingsOrange')
      setDarkMode(false)
    }
  }, [primaryColor, accentColor, darkMode])

  const theme = createTheme(themeProps)
  window.theme = theme

  return (
    <PTThemeContext.Provider
      value={{
        setDarkMode,
        getDarkMode,
        getPrimaryColor,
        setPrimaryColor,
        getAccentColor,
        setAccentColor
      }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </PTThemeContext.Provider>
  )
}

export default PTThemeProvider

export const usePTTheme = () => useContext(PTThemeContext)
