import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationPgms from './en'
import translationShared from 'shared/i18n/en'

const translation = {
  ...translationPgms,
  ...translationShared
}

export const defaultNS = 'translation'
export const resources = {
  en: {
    // The default namespace is "translation"
    translation
  }
} as const

// Add our translations to react-i18next types
declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof defaultNS
    resources: (typeof resources)['en']
  }
}

i18next.use(initReactI18next).init({
  lng: 'en',
  resources,
  defaultNS,
  interpolation: {
    escapeValue: false // react already safes from xss
  }
})

export default i18next
