import React, { useEffect, useMemo } from 'react'

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'

import { useAuth } from 'lib/providers/AuthProvider'
import { getGraphqlEndpointApiRoot } from 'lib/env'

interface ApolloClientProviderProps {
  children?: React.ReactNode
}

const ApolloClientProvider: React.FC<ApolloClientProviderProps> = ({ children }) => {
  // Get API_ROOT from injected environment, or guess depending on URL
  const endpoint = getGraphqlEndpointApiRoot()

  const cache = useMemo(() => new InMemoryCache(), [])

  useEffect(() => {
    persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage)
    })
  }, [cache])

  const httpLink = useMemo(
    () =>
      createHttpLink({
        uri: `${endpoint.http}/graphql`
      }),
    [endpoint.http]
  )

  const { getToken, setApolloClient } = useAuth()

  const authLink = useMemo(
    () =>
      setContext((op, ctx) => {
        if (op.operationName !== 'Login') {
          return {
            headers: {
              ...ctx.headers,
              ...(getToken() ? { authorization: `Bearer ${getToken()}` } : {})
            }
          }
        } else {
          return {}
        }
      }),
    [getToken]
  )

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors }) => {
        console.log('errorLink', graphQLErrors)
        cache.reset()
        // todo use this to catch authentication errors and open a popup or something to have them log back in
      }),
    [cache]
  )

  const client = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([errorLink, authLink, httpLink]),
        name: 'client',
        cache,
        defaultOptions: {
          query: {
            fetchPolicy: 'network-only'
          },
          watchQuery: {
            initialFetchPolicy: 'cache-and-network',
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-and-network'
          }
        }
      }),
    [authLink, cache, errorLink, httpLink]
  )

  // Update AuthProvider's Apollo client as soon as we can
  useEffect(() => setApolloClient(client), [client, setApolloClient])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloClientProvider
