import { grey } from '@mui/material/colors'
import { ThemeOptions } from '@mui/material/styles/createTheme'
import { getContrastRatio } from '@mui/system'

type ColorScheme = {
  displayName: string
  light: {
    primary:
      | string
      | {
          /** Main color for the theme */
          main: string
          /** Alternate color used for AppBar and some other solid fills */
          alt?: string
          /** Override for colored text, usually on dark themes without enough contrast */
          text?: string
        }
  }
  dark: {
    primary:
      | string
      | {
          /** Main color for the theme */
          main: string
          /** Alternate color used for AppBar and some other solid fills */
          alt?: string
          /** Override for colored text, usually on dark themes without enough contrast */
          text?: string
        }
  }
}

/**
 * Colors that only change between light and dark modes and not the color scheme
 */
const colorSchemeBase = {
  light: {
    primary: {
      default: '#222222',
      text: '#222222'
    },
    background: {
      paper: '#FFFFFF',
      default: '#EEEEEE',
      neutral: '#DDDDDD'
    },
    neutral: {
      default: grey[500],
      main: grey[800],
      text: grey[800],
      ...grey
    },
    typography: {
      monospaceBackground: 'rgba(0 0 0 / 10%)'
    },
    scrollbar: {
      background: '#EEEEEE',
      thumb: grey[800]
    },
    overrides: {
      dialog: {
        paperBorder: undefined
      }
    }
  },
  dark: {
    primary: {
      default: '#FFFFFF',
      text: '#FFFFFF'
    },
    background: {
      paper: '#222',
      default: '#0D0D0D',
      neutral: '#333'
    },
    neutral: {
      default: grey[700],
      text: grey[300],
      main: grey[300],
      // Brightness scale is inverted and slightly darker in dark mode:
      '50': '#000000',
      '100': '#131313',
      '200': grey[900],
      '300': grey[800],
      '400': grey[700],
      '500': grey[600],
      '600': grey[500],
      '700': grey[400],
      '800': grey[300],
      '900': grey[200],
      A100: grey['A700'],
      A200: grey['A400'],
      A400: grey['A200'],
      A700: grey['A100']
    },
    typography: {
      monospaceBackground: 'rgba(0 0 0 / 25%)'
    },
    scrollbar: {
      background: 'rgba(0, 0, 0, 0.3)',
      thumb: '#DDDDDD'
    },
    overrides: {
      dialog: {
        paperBorder: '1px solid rgba(255, 255, 255, 0.12)'
      }
    }
  }
}

/** Color schemes available in the dropdown */
export const colorSchemes: Record<string, ColorScheme> = {
  PingThingsBlue: {
    displayName: 'PingThings Blue',
    light: {
      primary: {
        main: '#49798c',
        text: '#1b5168'
      }
    },
    dark: {
      primary: {
        main: '#78a6b9',
        alt: '#4c798b',
        text: '#a3e1ff'
      }
    }
  },
  PingThingsTeal: {
    displayName: 'PingThings Teal',
    light: {
      primary: {
        main: '#41958b',
        alt: '#41958b',
        text: '#1d6e65'
      }
    },
    dark: {
      primary: {
        main: '#5eafa7',
        alt: '#1E6978'
        // text: '#8ce1e5'
      }
    }
  },
  PingThingsOrange: {
    displayName: 'PingThings Orange',
    light: {
      primary: {
        main: '#CF8241',
        // alt: '#a95b19',
        text: '#d76200'
      }
    },
    dark: {
      primary: {
        main: '#e18e4b',
        alt: '#a95b19',
        text: '#ff955c'
      }
    }
  }
}

// Update TypeScript defs for custom props in theme
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      neutral: React.CSSProperties['color']
    }
  }
  interface ThemeOptions {
    status?: {
      neutral: React.CSSProperties['color']
    }
  }

  interface Palette {
    neutral: Palette['primary']
    primaryText: React.CSSProperties['color']
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }

  interface TypographyVariants {
    monospace: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    monospace?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    monospace: true
  }
}

// Add colors to components that use them...
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
    primaryText: true
  }
}
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    neutral: true
    primaryText: true
  }
}
declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    neutral: true
    primaryText: true
  }
}
declare module '@mui/material' {
  interface TypeBackground {
    primary: React.CSSProperties['color']
    secondary: React.CSSProperties['color']
  }
}

export const getContrastText = (background: { main: string } | string, contrastThreshold: number) =>
  getContrastRatio(background?.['main'] ?? background, '#FFFFFF') >= contrastThreshold
    ? 'white'
    : 'black'

export const generateTheme = (
  primaryThemeName?: string,
  accentThemeName?: string,
  darkMode = false
) => {
  const primaryScheme = colorSchemes[primaryThemeName] ?? colorSchemes.PingThings_Blue
  const accentScheme = colorSchemes[accentThemeName] ?? colorSchemes.PingThings_Blue

  const baseTheme = darkMode ? colorSchemeBase.dark : colorSchemeBase.light
  const primaryTheme = darkMode ? primaryScheme.dark : primaryScheme.light
  const accentTheme = darkMode ? accentScheme.dark : accentScheme.light

  const primaryMain = (primaryTheme.primary as { main: string })?.main || primaryTheme.primary
  const primaryAlt = (primaryTheme.primary as { alt: string })?.alt || primaryTheme.primary
  const primaryText = (primaryTheme.primary as { text: string })?.text || primaryTheme.primary
  const accentMain = (accentTheme.primary as { main: string })?.main || accentTheme.primary
  const accentAlt = (accentTheme.primary as { alt: string })?.alt || accentTheme.primary

  const contrastThreshold = darkMode ? 3.5 : 3

  return {
    palette: {
      mode: darkMode ? 'dark' : 'light',
      contrastThreshold,
      action: {
        disabledOpacity: 0.25 // down from 0.38
      },
      primary: {
        main: primaryMain,
        default: baseTheme.primary.default
      },
      primaryText: {
        main: primaryText
      },
      secondary: {
        main: accentMain
      },
      warning: {
        main: '#FF7F11'
      },
      background: {
        paper: baseTheme.background.paper,
        default: baseTheme.background.default,
        neutral: baseTheme.background.neutral,
        primary: primaryAlt
      },
      text: {
        // default: getContrastText(baseTheme.background.paper, contrastThreshold),
        primaryColor: primaryText, // "primary" is the black/white text instead of primary color
        // secondary: darkMode ? '#c5c5c5' : '#406D82',
        neutral: baseTheme.neutral,
        main: getContrastText(baseTheme.background.paper, contrastThreshold)
      },
      neutral: baseTheme.neutral
    },
    typography: {
      h1: {
        fontSize: '57.3px'
      },
      h2: {
        fontSize: '47.8px'
      },
      h3: {
        fontSize: '39.8px'
      },
      h4: {
        fontSize: '33.2px'
      },
      h5: {
        fontSize: '27.6px'
      },
      h6: {
        fontSize: '23px'
      },
      subtitle1: {
        fontSize: '16px',
        fontWeight: 500
      },
      monospace: {
        fontFamily: '"Roboto Mono", "Consolas", "Courier New", "monospace"',
        fontWeight: 500,
        // backgroundColor: baseTheme.typography.monospaceBackground,
        // color: getContrastText(baseTheme.background.paper, contrastThreshold),
        color: undefined,
        padding: '2px 4px',
        borderRadius: '6px'
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '& ::-webkit-scrollbar': {
              backgroundColor: baseTheme.scrollbar.background,
              width: '8px',
              height: '8px'
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: baseTheme.scrollbar.thumb,
              borderRadius: '4px'
            }
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          standardSuccess: { backgroundColor: darkMode ? baseTheme.background : '#DAF6DB' }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: primaryAlt
          },
          colorNeutral: {
            backgroundColor: '#222222',
            color: '#FFFFFF'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '25px'
          },
          containedPrimary: {
            backgroundColor: primaryAlt,
            color: getContrastText(primaryAlt, contrastThreshold)
          },
          containedSecondary: {
            backgroundColor: accentAlt,
            color: getContrastText(accentAlt, contrastThreshold)
          },
          outlined: {
            root: {
              color: primaryText
            }
          },
          text: {
            root: {
              color: primaryText
            }
          }
        },
        defaultProps: {
          disableElevation: true
        }
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            color: 'inherit', // default is rgba(0, 0, 0, 0.6)
            textUnderlineOffset: '2px' // pretend to be a link when underlined
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            border: baseTheme.overrides.dialog.paperBorder
          }
        }
      },
      MuiCollapse: {
        styleOverrides: {
          root: {
            flexShrink: '0'
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            color: getContrastText(baseTheme.background.paper, contrastThreshold)
          }
        }
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.background.paper,
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(2px)'
          },
          invisible: {
            backdropFilter: 'none'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            colorPrimary: {
              color: primaryText
            }
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: primaryText
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: primaryText
            }
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: primaryText
            }
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: baseTheme.neutral[800],
            paddingTop: 0,
            paddingBottom: 0,
            '&.Mui-selected': {
              color: primaryText
            }
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            // '& .MuiTableRow-root': {
            //   backgroundColor: baseTheme.background.paper
            //   // backgroundColor: 'white'
            // },
            '& .MuiTableCell-root': {
              borderBottom: 'none'
            }
          }
        }
      },
      MuiTooltip: {
        defaultProps: {
          disableInteractive: true
        }
      }
    },
    shape: {
      borderRadius: 8
    }
  } as Partial<ThemeOptions> as unknown as ThemeOptions
}
